import React, { Component } from 'react';
import './Game.css';

class CardView extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (!this.props.matched && !this.props.imageUp) {
            this.props.onClick(this.props.id, this.props.image);
        }
    }

    render() {
        let imPath = `${window.location.href}covers/0${this.props.image}.jpg`;
        // let imPath = this.props.image <= 9 ? `./img/covers/0${this.props.image}.jpg` : `./img/covers/${this.props.image}.jpg`;
        if (!this.props.imageUp) {
            imPath = `${window.location.href}/logo512.png`;
        }
        // console.log(imPath);
        
        let className = 'Card';
        if (this.props.matched) {
            className = className + ' border border-green-600';
        }

        return (
            <img className={className} src={imPath} alt='' onClick={this.onClick} />
        );
    };
};

export default CardView;
